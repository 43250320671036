import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  GridItem,
  Image,
  Text,
  ScaleFade,
} from '@chakra-ui/react'

import herramientas from '../../administracion/jsons_images/Home/Herramientas/herramientas'

export default function Herramientas() {
  const history = useNavigate();

  const handleViewMore = (link) => {
    history(link)
  }

  return (
    <Grid
      bgImage={{
        base: herramientas.bg_m_herramientas,
        md: herramientas.bg_w_herramientas
      }}
      bgSize="cover"
      backgroundRepeat='no-repeat'
      pt={{ base: '30%', md: "1px", lg: '1%' }}
    >
      <GridItem>
        <Grid
          margin="auto"
          width={{ lg: '100%', base: '80%' }}
          padding={{ lg: '10px', base: '1%' }}
          textAlign="center"
        >
          <Text fontSize="30px" fontWeight={"bold"}>
            {herramientas.titulo}
          </Text>
          <Text fontSize="30px" fontWeight={"bold"} color="#B2D237">
            {herramientas.subtitulo1}
          </Text>
        </Grid>
        <Grid margin="auto" width={{ lg: '40%', base: '80%' }} textAlign="center">
          <Text fontSize="16px">
            {herramientas.subtitulo2}
          </Text>
        </Grid>
      </GridItem>

      <Grid px={{ base: '5%', lg: '20%' }} pt={{ base: '8%', lg: '2%' }}>
        <Grid
          templateColumns={{
            base: 'repeat(2, 2fr)',
            sm: 'repeat(2, 2fr)',
            md: 'repeat(3, 2fr)',
            lg: 'repeat(3, 2fr)',
          }}
          gridGap={{ lg: 6, base: 1 }}

        >
          {/* erp */}
          <Grid borderRadius={'15px'} border="2px" borderColor="#B2D237" flexDirection="column">
            <Grid px={{ lg: '5%', base: '1%' }} py="5%">
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image src={herramientas.ERP.logo} alt="Logo_erp" />
              </ScaleFade>
              <Text textAlign={"center"} fontSize="20px">{herramientas.ERP.title}</Text>
              <Grid p={3} pb="40%">
                <Text fontSize="12px">
                  {herramientas.ERP.descripcion}
                </Text>
              </Grid>
              <Grid mx="auto" mt="auto">
                <Button
                  backgroundColor="#A5C918"
                  color="white"
                  borderRadius="20px"
                  onClick={() => handleViewMore('/Productos/ERP/')}
                  _hover={{
                    textDecoration: 'none',
                    bg: '#7a9413',
                  }}
                >
                  <Text fontSize="12px" padding="0px 20px 0px 20px">
                    Ver más
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* pdv */}
          {/* <Grid borderRadius={'15px'} border="2px" borderColor="#B2D237" flexDirection="column">
            <Grid px={{ lg: '5%', base: '1%' }} py="5%">
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image src={herramientas.PDV.logo} alt="Logo_pdv" />
              </ScaleFade>
              <Text textAlign={"center"} fontSize="20px">{herramientas.PDV.title}</Text>
              <Grid p={3} pb="40%">
                <Text fontSize="12px">
                  {herramientas.PDV.descripcion}
                </Text>
              </Grid>

              <Grid mx="auto" mt="auto">
                <Button
                  backgroundColor="#A5C918"
                  color="white"
                  borderRadius="20px"
                  onClick={() => handleViewMore('/Productos/PDV/')}
                  _hover={{
                    textDecoration: 'none',
                    bg: '#7a9413',
                  }}
                >
                  <Text fontSize="12px" padding="0px 20px 0px 20px">
                    Ver más
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid> */}
          {/* trib */}
          <Grid borderRadius={'15px'} border="2px" borderColor="#B2D237" flexDirection="column">
            <Grid px={{ lg: '5%', base: '1%' }} py="5%">
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image src={herramientas.Tributario.logo} alt="Logo_trib" />
              </ScaleFade>
              <Text textAlign={"center"} fontSize="20px">{herramientas.Tributario.title}</Text>
              <Grid p={3} pb="40%">
                <Text fontSize="12px">
                  {herramientas.Tributario.descripcion}
                </Text>
              </Grid>
              <Grid mx="auto" mt="auto">
                <Button
                  backgroundColor="#A5C918"
                  color="white"
                  borderRadius="20px"
                  onClick={() => handleViewMore('/Productos/tributario/')}
                  _hover={{
                    textDecoration: 'none',
                    bg: '#7a9413',
                  }}
                >
                  <Text fontSize="12px" padding="0px 20px 0px 20px">
                    Ver más
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* cont */}
          <Grid borderRadius={'15px'} border="2px" borderColor="#B2D237" flexDirection="column">
            <Grid px={{ lg: '5%', base: '1%' }} py="5%">
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image src={herramientas.Contador.logo} alt="Logo_cont" />
              </ScaleFade>
              <Text textAlign={"center"} fontSize="20px">{herramientas.Contador.title}</Text>
              <Grid p={3} pb="40%">
                <Text fontSize="12px">
                  {herramientas.Contador.descripcion}
                </Text>
              </Grid>
              <Grid mx="auto" mt="auto">
                <Button
                  backgroundColor="#A5C918"
                  color="white"
                  borderRadius="20px"
                  onClick={() => handleViewMore('/Productos/contador/')}
                  _hover={{
                    textDecoration: 'none',
                    bg: '#7a9413',
                  }}
                >
                  <Text fontSize="12px" padding="0px 20px 0px 20px">
                    Ver más
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
