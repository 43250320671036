import React, { useEffect } from "react";
import {
  Grid,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import lottie from "lottie-web";

import Cantidad from "../Shared/Simular/Cantidad"
import pdv from "../../administracion/jsons_images/Simular/animations/pdv.json"
import pc2 from "../../administracion/jsons_images/Simular/animations/pc2.json"

export default function Software({ ...props }) {
  const {
    answers,
    setAnswers,
    currentQuestion,
    setCurrentQuestion,
    typeSoftware,
    setTypeSoftware,
    setProgressQ,
  } = props
  const [isPDV, setIsPDV] = React.useState(false);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#software-an"),
      animationData: pdv
    });
    lottie.loadAnimation({
      container: document.querySelector("#pc-an"),
      animationData: pc2
    });
    setProgressQ({
      total: 1,
      actual: 1
    })
  }, []);

  useEffect(() => {
    if (isPDV) {
      setAnswers({
        ...answers,
        PDV: {
          pregunta: currentQuestion.subAnswer.question,
          respuesta: "0"
        }
      })
    } else {
      delete answers.PDV
    }
  }, [isPDV]);

  const handleSetType = (option) => {
    if (option === "PDV") {
      setIsPDV(true)
    } else {
      setIsPDV(false)
    }
    setProgressQ({
      total: 2,
      actual: 1
    })
    setCurrentQuestion({
      ...currentQuestion,
      answer: currentQuestion.answers[option].nextQuestion
    })
    setTypeSoftware(option)
    setAnswers({
      ...answers,
      Q_TIPO: {
        pregunta: currentQuestion.question,
        respuesta: currentQuestion.answers[option].value
      }
    })
  }

  return (
    <>
      <Grid m="auto" pt="5%">
        <Text fontSize="16px" fontWeight="bold">
          {currentQuestion.question}
        </Text>
      </Grid>
      <Grid
        display="flex"
        gap={2}
        m="auto"
        pt="3%"
      >
        {/* <Button
          bg="transparent"
          border="2px"
          borderRadius="xl"
          borderColor={typeSoftware === "PDV" ? "#BEE625" : "#EEEFED"}
          onClick={() => handleSetType("PDV")}
          h="100%"
          py="5%"
          px="25%"
        >
          <HStack
            spacing={8}
            alignItems={'center'}
          >
            <Grid>
              <div id="software-an" style={{ width: 150, height: 150, paddingBottom: "14%" }} />
              <Text fontSize="12px" fontWeight={100} color="#A5C918" >Quiero un</Text>
              <Text fontSize="12px" fontWeight="bold" color="#A5C918">Punto de venta</Text>
            </Grid>
          </HStack>
        </Button> */}
        <Button
          bg="white"
          border="2px"
          borderRadius="xl"
          borderColor={typeSoftware === "CONTABLE" ? "#BEE625" : "#EEEFED"}
          onClick={() => handleSetType("CONTABLE")}
          h="100%"
          py="5%"
          px="25%"
        >
          <HStack
            spacing={8}
            alignItems={'center'}
          >
            <Grid>
              <div id="pc-an" style={{ width: 150, height: 150, paddingBottom: "14%" }} />
              <Text fontSize="12px" fontWeight={100} color="#A5C918" >Quiero un software</Text>
              <Text fontSize="12px" fontWeight="bold" color="#A5C918">Contable</Text>
            </Grid>
          </HStack>
        </Button>
      </Grid>

      {isPDV &&
        <Grid>
          <Cantidad
            title={currentQuestion.subAnswer.question}
            subtitle={currentQuestion.subAnswer.subtitle}
            id={currentQuestion.subAnswer.id}
            answers={answers}
            setAnswers={setAnswers}
            defVal={0}
            min={0}
            max={24}
          />
        </Grid>
      }
    </>
  );
}
