import contacto_m from '../../Contacto/contacto_m_bg.jpg'
import contacto_w from '../../Contacto/contacto_w_bg.jpg'
import facebook_w_logo from '../../Navegacion/facebook_logo.svg'
import facebook_m_logo from '../../Navegacion/facebook_m_logo.svg'
import instagram_w_logo from '../../Navegacion/instagram_logo.svg'
import instagram_m_logo from '../../Navegacion/instagram_m_logo.svg'
import youtube_w_logo from '../../Navegacion/youtube_logo.svg'
import whatsapp_logo from "../../Navegacion/whatsapp_logo.svg"

const contacto = {
  bg_m: contacto_m,
  bg_w: contacto_w,
  titulo1: 'Contácta',
  titulo2: 'nos',
  ubicacion: {
    calle: 'Francisco Bilbao 1129,',
    oficina: 'Oficina 1001, Osorno,',
    region: 'Los Lagos',
  },
  email: 'ventasonline@audisoft.cl',
  telefono: '+56953631701',
  facebook: {
    logo_w: facebook_w_logo,
    logo_m: facebook_m_logo,
    url: 'https://www.facebook.com/audisoft',
  },
  instagram: {
    logo_w: instagram_w_logo,
    logo_m: instagram_m_logo,
    url: 'https://www.instagram.com/audisoft_chile/',
  },
  youtube: {
    logo_w: youtube_w_logo,
    logo_m: youtube_w_logo,
    url: 'https://www.youtube.com/channel/UCyQupko4_ggk0BgfDHoewuQ/featured',
  },
  whatsapp: {
    logo: whatsapp_logo,
  },
}

export default contacto
