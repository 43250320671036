import React from 'react'
import {
  Box,
  Button,
  Link,
  Grid,
  Stack,
  Text,
  IconButton,
  GridItem,
  Image,
} from '@chakra-ui/react'

import contacto from "../../administracion/jsons_images/Shared/Contacto/contacto"
// import facebook_logo from "../../assets/images/Navigation/facebook_logo.svg"
// import instagram_logo from "../../assets/images/Navigation/instagram_logo.svg"
// import youtube_logo from "../../assets/images/Navigation/youtube_logo.svg"

export default function Footer() {

  return (
    <Box
      color="white"
      bg="#262626"
      py={{ base: '20%', md: '5%' }}
      px={{ base: '5%', md: '20%' }}
    >
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        templateRows={{
          base: 'repeat(2, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          lg: 'repeat(1, 1fr)',
        }}
        gap={10}
      >
        <GridItem colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }} rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}>
          <Grid>
            <Stack align={'flex-start'}>
              <Link fontSize={{ md: '16px', base: '14px' }} href={'/'}>
                Inicio
              </Link>
              <Link fontSize={{ md: '16px', base: '14px' }} href={'/Nosotros'}>
                Nosotros
              </Link>
              {/* <Link fontSize={{ md: '16px', base: '14px' }} href={'/Productos/ERP'}>
                Productos
              </Link> */}
              {/* <Link fontSize={{ md: '16px', base: '14px' }} href={'/Planes'}>
                Planes
              </Link> */}
              {/* <Link fontSize={{ md: '16px', base: '14px' }} href={'/Simular-cotizacion'}>
                Simula tu cotización
              </Link> */}
              <Link fontSize={{ md: '16px', base: '14px' }} href={'/Contacto'}>
                Contacto
              </Link>
            </Stack>
            {/* <Stack align={'flex-start'} pt="5%">
              <Button border="2px" borderRadius="20px" borderColor="#BEE625" backgroundColor="#262626" onClick={() => window.location.href = "http://www.clientes.audisoft.cl"}>
                <Text color="#BEE625">Soy cliente</Text>
              </Button>
              <Button border="2px" borderRadius="20px" borderColor="#BEE625" backgroundColor="#262626" onClick={() => window.location.href = "https://empleado.audisoft.cl"}>
                <Text color="#BEE625">Soy colaborador</Text>
              </Button>
            </Stack> */}
          </Grid>
        </GridItem>

        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}

        >
          <Grid>

            <Stack align={'flex-start'} spacing={2}>
              <Stack>
                <Text fontSize={{ md: '16px', base: '14px' }} color="#BEE625" fontWeight="bold">
                  Email:
                </Text>
                <Text fontSize={{ md: '12px', base: '14px' }}>{contacto.email}</Text>
              </Stack>
              <Stack>
                <Text fontSize={{ md: '16px', base: '14px' }} color="#BEE625" fontWeight="bold">
                  Teléfono:
                </Text>
                <Text fontSize={{ md: '12px', base: '14px' }}>{contacto.telefono}</Text>
              </Stack>
            </Stack>
          </Grid>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}

        >
          <Grid>
            <Stack align={'flex-start'}>
              <Text fontSize="16px" color="#BEE625" fontWeight="bold">
                Ubicación
              </Text>
              <Text fontSize={{ md: '12px', base: '14px' }}>{contacto.ubicacion.calle}</Text>
              <Text fontSize={{ md: '12px', base: '14px' }}>{contacto.ubicacion.oficina}</Text>
              <Text fontSize={{ md: '12px', base: '14px' }}>{contacto.ubicacion.region}</Text>
            </Stack>
          </Grid>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}

        >
          <Grid>
            <Stack align={'flex-start'} spacing={2}>
              <Text fontSize={{ md: '16px', base: '14px' }} color="#BEE625" fontWeight="bold">
                Síguenos:
              </Text>
              <Stack direction={'row'} spacing={6}>
                <IconButton
                  icon={<Image src={contacto.instagram.logo_w} boxSize='30px' alt="instagram_logo" />}
                  as="a"
                  href={contacto.instagram.url}
                  isRound="true"
                  bg="#262626"
                  _hover={{
                    bg: "#262626"
                  }}
                ></IconButton>
                <IconButton
                  icon={<Image src={contacto.facebook.logo_w} boxSize='30px' alt="facebook_logo" />}
                  as="a"
                  href={contacto.facebook.url}
                  isRound="true"
                  bg="#262626"
                  _hover={{
                    bg: "#262626"
                  }}
                ></IconButton>
                <IconButton
                  icon={<Image src={contacto.youtube.logo_w} boxSize='30px' alt="youtube_logo" />}
                  as="a"
                  href={contacto.youtube.url}
                  isRound="true"
                  bg="#262626"
                  _hover={{
                    bg: "#262626"
                  }}
                ></IconButton>
              </Stack>

            </Stack>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  )
}
